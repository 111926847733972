.route-panel-root {
    position: absolute;
    width: 100vw; height: 100vh;
    margin: 0;
    left: 0; top: 0;
    overflow: hidden;
}

.route-panel-header {
    position: absolute;
    width: calc(100vw - 100px); height: 120px;
    left: 50px; right: 50px;
    top: 20px;
    bottom: 0;
    overflow: hidden;
    white-space: nowrap;
}

.route-panel-body {
    position: absolute;
    width: calc(100vw - 100px); height: calc(100vh - 160px);
    left: 50px; right: 50px;
    top: 140px;
    bottom: 50px;
    overflow: hidden;
}