.list-button {
    min-width: 150px;
    min-height: 150px;
    display: grid;
    place-items: center;
    border-radius: 50px;
}

.fill {
    width: 100%;
    height: 100%;
}